import { FC, useContext } from 'react';
import { ExternalLink, Loader, Card } from '../../components';
import { useQuery } from 'react-query';
import { Box, Typography, CircularProgress, Button, styled, TypographyProps } from '@mui/material';
import { IAddressVerification, ISimpleDateRange } from '../../models';
import { getAddressVerification } from '../../fetch';
import { UserContext } from '../../context';
import { getLegacyUrl } from '../../helpers';
import { Link } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { Paths } from '../../constants';

interface IAddressVerificationProps {
  dateRange: ISimpleDateRange;
}

export const AddressVerification: FC<IAddressVerificationProps> = ({ dateRange }) => {
  const { user } = useContext(UserContext);
  const { isLoading, data } = useQuery<IAddressVerification, Error>(
    ['addressVerification', dateRange],
    () =>
      getAddressVerification({
        officeId: user?.officeId,
      }),
    {
      notifyOnChangeProps: 'tracked',
      staleTime: Infinity,
    }
  );
  const percent: number | null =
    data?.verifiedAddresses && data?.totalAddresses
      ? Number(((data.verifiedAddresses / data.totalAddresses) * 100).toFixed(0))
      : 0;
  const legacyUrl = getLegacyUrl?.();

  const { v2Customers } = useFlags();

  return (
    <Card
      cardTitleProps={{ title: 'Site Address Verification' }}
      sxProps={{ height: '100%', paddingBottom: 0 }}
    >
      {isLoading && (
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          minHeight="13.9rem"
        >
          <Loader position="centered" />
        </Box>
      )}
      {!isLoading && data && (
        <>
          <ContentWrapper>
            <ProgressWrapper>
              <StyledActiveProgress
                variant="determinate"
                // divider by two so we only show the half circle
                value={percent ? percent / 2 : 0}
                color="secondary"
                data-testid="progress-dial"
              />
              <StyledCircularProgress
                variant="determinate"
                // divider by two so we only show the half circle
                value={100 / 2}
                color="inherit"
              />
              <PercentText>
                <Typography sx={{ fontSize: 36, fontWeight: 'bold' }}>{percent}%</Typography>
                <NonVerifiedText variant="caption">
                  {percent === 0
                    ? 'No addresses to verify'
                    : percent !== 100
                    ? 'You have not yet verified all addresses.'
                    : 'All addresses have been verified.'}
                </NonVerifiedText>
              </PercentText>
            </ProgressWrapper>
          </ContentWrapper>
          <Box display="flex" alignItems="center">
            <Box sx={{ flex: 1 }}>
              <Typography sx={{ fontSize: 24, fontWeight: 'bold' }}>
                {data.verifiedAddresses}
                <TargetText component="span">/{data.totalAddresses} target</TargetText>
              </Typography>
            </Box>
            <Box>
              {!v2Customers && (
                <StyledExternalLink
                  data-testid="verify-addresses-button"
                  type="white"
                  target="_blank"
                  to={`${legacyUrl}/Office/Address/Verify`}
                >
                  <FontAwesomeIcon icon={faCheck} style={{ marginRight: '.5rem' }} />
                  Verify Addresses
                </StyledExternalLink>
              )}

              {v2Customers && (
                <Button
                  component={Link}
                  color="primary"
                  size="small"
                  to={Paths.verifyAddresses.url}
                  startIcon={<FontAwesomeIcon icon={faCheck} />}
                  data-testid="verify-addresses-button"
                >
                  Verify Addresses
                </Button>
              )}
            </Box>
          </Box>
        </>
      )}
      {!isLoading && !data && (
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          minHeight="13.9rem"
          data-testid="no-addresses-to-verify"
        >
          <Typography>No Address Verification</Typography>
        </Box>
      )}
    </Card>
  );
};

const ContentWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '12.6rem',
  paddingBottom: '1rem',
}));

const PercentText = styled(Box)(({ theme }) => ({
  top: '40px',
  left: 0,
  bottom: 0,
  right: 0,
  position: 'absolute',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
}));

const ProgressWrapper = styled(Box)(({ theme }) => ({
  position: 'relative',
  display: 'inline-flex',
  width: '100%',
  justifyContent: 'center',
  overflow: 'hidden',
}));

const StyledCircularProgress = styled(CircularProgress)(({ theme }) => ({
  color: theme.palette.grey[500],
  position: 'absolute',
  transform: `rotate(180deg) !important`,
  width: `300px !important`,
  height: `19rem !important`,
  '@media (max-width: 400px)': {
    width: `250px !important`,
    height: `15rem !important`,
    top: 10,
  },
  '@media (min-width: 1200px) and (max-width: 1350px)': {
    width: `250px !important`,
    height: `16rem !important`,
  },
}));

const StyledActiveProgress = styled(CircularProgress)(({ theme }) => ({
  transform: `rotate(180deg) !important`,
  width: `300px !important`,
  height: `19rem !important`,
  position: 'relative',
  zIndex: 1,
  '@media (max-width: 400px)': {
    width: `250px !important`,
    height: `15rem !important`,
    top: 10,
  },
  '@media (min-width: 1200px) and (max-width: 1350px)': {
    width: `250px !important`,
    height: `16rem !important`,
  },
}));

const TargetText = styled(Typography)<TypographyProps>(({ theme }) => ({
  fontSize: 14,
  color: theme.palette.grey[600],
}));

const NonVerifiedText = styled(Typography)(({ theme }) => ({
  fontSize: 11,
  color: theme.palette.grey[600],
  textAlign: 'center',
  maxWidth: 'initial',
  '@media (min-width: 1200px) and (max-width: 1350px)': {
    maxWidth: '10rem',
  },
}));

const StyledExternalLink = styled(ExternalLink)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  padding: '0.375rem 0.75rem',
  fontSize: '0.875rem',
  borderRadius: '0.2rem',
  borderColor: theme.palette.primary.main,
  color: '#fff',
  zIndex: 3,
  cursor: 'pointer',
  '&:hover': {
    color: '#fff',
    backgroundColor: '#1b3783',
    borderColor: '#1a347b',
  },
}));
