import { FC, forwardRef, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useSnackbar } from 'notistack';
import { Loader, GridDataFetcher, useDataGrid, Card, Link } from '../../components';
import { Box, Divider, Typography, Button } from '@mui/material';
import {
  EAccountProvider,
  EAccountProviderEntryType,
  IAccount,
  IAccountAvailableColumn,
  IAccountingProviderCustomerImportStatus,
} from '../../models';
import {
  getAccounts,
  getAllBillingGroups,
  getCustomerImportStatus,
  importCustomersFromAccountingProvider,
} from '../../fetch';
import { ESearchParams, SearchParamsContext, UserContext } from '../../context';
import {
  formatShortFriendlyDateWithTime,
  hasCorrectUserPermissions,
  removeLocalStorage,
} from '../../helpers';
import { CustomersFilters } from './CustomersFilters';
import { CustomersDataGrid } from './CustomersDataGrid';
import { LSKeys, Permissions } from '../../constants';
import { useConfirm, useDebounce } from '../../hooks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faFileImport, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { CustomViewsWrapper } from './custom-views-wrapper';
import { BrandingContext } from '../../context/branding-context';
import { CustomersApplyRateCodesModal } from './customers-apply-rate-codes-modal';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useQuery } from 'react-query';

interface ICustomersList {}

export const CustomersList: FC<ICustomersList> = () => {
  const { user, accountingProvider } = useContext(UserContext);
  const { enqueueSnackbar } = useSnackbar();
  const { isPoolService } = useContext(BrandingContext);
  const { customerRates, quickbooks: quickBooksFF } = useFlags();

  const confirm = useConfirm();

  const hasQBEnabled = useMemo(() => {
    if (
      accountingProvider?.provider === EAccountProvider.QuickBooks &&
      accountingProvider?.entryType === EAccountProviderEntryType.Invoice &&
      quickBooksFF
    ) {
      return true;
    } else {
      return false;
    }
  }, [accountingProvider, quickBooksFF]);

  const {
    isLoading: isLoadingImportStatus,
    data: customerImport,
    refetch: refetchQBImportStatus,
  } = useQuery<IAccountingProviderCustomerImportStatus, Error>(
    ['customer-import-status', hasQBEnabled],
    async () => getCustomerImportStatus(user?.officeId ?? ''),
    {
      enabled: !!user?.officeId && hasQBEnabled,
    }
  );

  const handleQBImport = async () => {
    const result = await confirm('Are you sure you want to import customers from QuickBooks?');
    if (result) {
      const billingGroups = await getAllBillingGroups({ officeId: user?.officeId });
      const hasDefaultBillingGroup = billingGroups?.records?.find(
        group => group.isImportDefault
      )?.billingGroupId;
      if (!hasDefaultBillingGroup) {
        enqueueSnackbar(
          'Please set a default billing group from the Setup > Billing Groups page before importing customers from QuickBooks.',
          {
            variant: 'error',
          }
        );
      } else {
        try {
          await importCustomersFromAccountingProvider(user?.officeId ?? '');
          enqueueSnackbar(
            'Import has been added to the queue. An email will be sent once import is complete.',
            {
              variant: 'success',
            }
          );
          refetchQBImportStatus();
        } catch (err: any) {
          enqueueSnackbar(err?.Detail ?? 'Error importing customers', {
            variant: 'error',
          });
        }
      }
    }
  };

  const {
    setSearchParams,
    arrSearchParamsValues,
    paramFilterLetter,
    paramSearchValue,
    paramCustomView,
    queryParams,
  } = useContext(SearchParamsContext);

  const [filters, setFilters] = useState<{
    filterLetter?: string;
    officeId?: string;
  } | null>({});
  const [selectedLetterOption, setSelectedLetterOption] = useState<string>(
    paramFilterLetter ? paramFilterLetter : ''
  );
  const [availableColumns, setAvailableColumns] = useState<IAccountAvailableColumn[]>([]);
  const [customViewSelected, setCustomViewSelected] = useState<string>(
    paramCustomView ? paramCustomView : ''
  );

  const [searchValue, setSearchValue] = useState<string>(paramSearchValue ? paramSearchValue : '');
  const [searchedValue, setSearchedValue] = useState<string>(
    paramSearchValue ? paramSearchValue : ''
  );

  const [ratesModalIsOpen, setRatesModalIsOpen] = useState(false);

  useEffect(() => {
    // selectedLetterOption is set from the URL params on page load
    if (isPoolService && paramFilterLetter && !selectedLetterOption) {
      setSelectedLetterOption(paramFilterLetter);
    }
    // searchValue & searchedValue is set from the URL params on page load
    if (isPoolService && paramSearchValue && !searchedValue) {
      setSearchValue(paramSearchValue);
      setSearchedValue(paramSearchValue);
    }
    // customViewSelected is set from the URL params on page load
    if (isPoolService && paramCustomView && !customViewSelected) {
      setCustomViewSelected(paramCustomView);
    }
    // Allow selectedLetterOption to be reset when there are no URL params on page load
    if (isPoolService && !paramFilterLetter && selectedLetterOption) {
      setSelectedLetterOption('');
    }
    // Allow searchValue & searchedValue to be reset when there are no URL params on page load
    if (isPoolService && !paramSearchValue && searchedValue) {
      setSearchValue('');
      setSearchedValue('');
    }
    // Allow customViewSelected to be reset when there are no URL params on page load
    if (isPoolService && !paramCustomView && customViewSelected) {
      setCustomViewSelected('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paramFilterLetter, paramSearchValue, paramCustomView]);
  // Due to immediate filtering, the search params are set here so that we get the latest value
  // Otherwise we would be a value behind from what is currently set, making redirects incorrect
  // Also checking for loading state so that we don't get a redirect injected into the URL when coming from a detail page
  useEffect(() => {
    if (isPoolService && !isLoading) {
      setSearchParams({
        [ESearchParams.filterLetter]: !!selectedLetterOption ? selectedLetterOption : undefined,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  useEffect(() => {
    // Needs to be separate because it would reset the filterLetter param otherwise
    if (isPoolService && customViewSelected) {
      setSearchParams({
        [ESearchParams.customView]: !!customViewSelected ? customViewSelected : undefined,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customViewSelected]);
  
  const dataFetcher: GridDataFetcher<IAccount> = useCallback(
    async ({ page, perPage, sortColumn, sortDirection, isInitialSort, searchParams }) => {
      // convert the search params to an array of values
      const arrSearchParamsValues = searchParams
        ? Array.from(searchParams.values())?.filter(p => !p.includes('redirect'))
        : [];
      // don't call the api when this query param is present or on services page
      if (
        (queryParams && queryParams.includes('redirect')) ||
        (isPoolService && !searchParams.get(ESearchParams.customView)) ||
        (!isPoolService && !customViewSelected)
      ) {
        return {
          continueLoading: true,
        };
      }
      let params: any = {
        sortDirection: sortDirection || 'asc',
        sortBy: isInitialSort ? '' : sortColumn,
        isInitialSort,
        page: page + 1,
        perPage,
        officeId: user?.officeId as string,
        filterLetter: selectedLetterOption,
        customViewId: customViewSelected,
        search: searchedValue,
      };
      if (arrSearchParamsValues?.length > 0) {
        params = {
          ...params,
          sortBy:
            searchParams?.get(ESearchParams.sort) !== null
              ? searchParams?.get(ESearchParams.sort)!
              : params.sortBy,
          sortDirection: (searchParams?.get(ESearchParams.sortDirection) !== null
            ? searchParams?.get(ESearchParams.sortDirection)!
            : params.sortDirection) as 'asc' | 'desc',
          filterLetter:
            searchParams?.get(ESearchParams.filterLetter) !== null
              ? searchParams?.get(ESearchParams.filterLetter)!
              : params.filterLetter,
          search:
            searchParams?.get(ESearchParams.searchValue) !== null
              ? searchParams?.get(ESearchParams.searchValue)!
              : params.search,
          customViewId:
            searchParams?.get(ESearchParams.customView) !== null
              ? searchParams?.get(ESearchParams.customView)!
              : params.customViewId,
        };
      }

      try {
        const res = await getAccounts(params);

        setAvailableColumns(res.availableColumns);

        return {
          rows: res.records,
          rowCount: res.totalRecordCount,
        };
      } catch (error) {
        enqueueSnackbar(`Error loading customers, please try again.`, {
          variant: 'error',
        });
        throw error;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    isPoolService
      ? [arrSearchParamsValues.length]
      : [customViewSelected, selectedLetterOption, searchedValue]
  );

  const {
    rows,
    isLoading,
    page,
    pageSize: perPage,
    rowCount: recordCount,
    sortModel,
    onPageChange,
    onPageSizeChange,
    onSortModelChange,
    setPage,
    setSortDirection,
    setIsInitialSort,
    refetch: reloadCustomers,
  } = useDataGrid<IAccount>({
    initialOptions: {
      page: 0,
      pageSize: 50,
      gridKeyName: 'customers-grid',
      sortDirection: 'asc',
      isInitialSort: true,
    },
    dataFetcher,
    shouldUseSearchParams: isPoolService,
  });

  useDebounce(
    () => {
      // Bug Fix: When paged to something other than 0, reset pagination on search so that results are displayed
      setPage(0);
      setSearchedValue(searchValue);
      if (isPoolService) {
        setSearchParams({
          [ESearchParams.searchValue]: !!searchValue ? searchValue : undefined,
        });
      }
    },
    800,
    [searchValue]
  );

  const hasBulkRatePermissions = useMemo(() => {
    return (
      customerRates &&
      (hasCorrectUserPermissions(Permissions.EditAccountInfo, user!) ||
        hasCorrectUserPermissions(Permissions.ViewCustomers, user!))
    );
  }, [user, customerRates]);
  return (
    <>
      <Box
        display="flex"
        alignItems={{
          xs: 'auto',
          lg: 'flex-start',
        }}
        flexDirection={{
          xs: 'column',
          lg: 'row',
        }}
        mb={2}
        gap={2}
        flexWrap="wrap"
      >
        <CustomViewsWrapper
          setCustomViewSelected={val => {
            setCustomViewSelected(val);
          }}
          customViewSelected={customViewSelected}
          handleCustomViewChange={() => {
            setPage(0);
            setIsInitialSort(true);
            // remove from local storage
            removeLocalStorage(LSKeys.CUSTOM_VIEW);

            //Pinch resets on change of custom view
            if (!isPoolService) {
              setSelectedLetterOption('');
              setSearchValue('');
              setSearchedValue('');
              setSortDirection('asc');
            }
          }}
        />
        <Box>
          <Box
            display="flex"
            sx={{ flex: 1 }}
            gap={{
              xs: 2,
              lg: 1,
            }}
            justifyContent={{ xs: 'flex-start', md: 'flex-end' }}
            flexDirection={{
              xs: 'column',
              sm: 'row',
            }}
            width={{
              xs: '100%',
              lg: 'auto',
            }}
          >
            {hasBulkRatePermissions && (
              <Button
                startIcon={<FontAwesomeIcon icon={faEdit} />}
                disabled={!customViewSelected || isLoading}
                onClick={() => {
                  setRatesModalIsOpen(true);
                }}
                color="primary"
                sx={{
                  width: {
                    xs: '100%',
                    lg: 'auto',
                  },
                }}
              >
                Apply Rate Codes
              </Button>
            )}

            {hasQBEnabled && (
              <Button
                startIcon={<FontAwesomeIcon icon={faFileImport} />}
                disabled={
                  isLoading || isLoadingImportStatus || customerImport?.status === 'Running'
                }
                onClick={() => handleQBImport()}
                color="primary"
                sx={{
                  width: {
                    xs: '100%',
                    lg: 'auto',
                  },
                }}
              >
                {customerImport?.status === 'Running' ? 'QB Import Running' : 'Import QB Customers'}
              </Button>
            )}
            <Button
              to={`/customers/new?redirect=${encodeURIComponent(
                `/customers${queryParams ? `?${queryParams}` : ''}`
              )}`}
              startIcon={<FontAwesomeIcon icon={faPlusCircle} />}
              color="primary"
              component={forwardRef((props: any, _ref) => {
                return <Link {...props} type="white" />;
              })}
              sx={{
                width: {
                  xs: '100%',
                  lg: 'auto',
                },
              }}
            >
              Add New Customer
            </Button>
          </Box>
          {customerImport?.lastModified && (
            <Typography
              variant="caption"
              sx={{ display: 'block', mt: 1 }}
              color="textSecondary"
              textAlign="right"
            >
              Last QuickBooks customer import:{' '}
              {formatShortFriendlyDateWithTime(customerImport.lastModified)}
            </Typography>
          )}
        </Box>
      </Box>
      <Card>
        <Box>
          <CustomersFilters
            isLoading={isLoading}
            setSelectedLetterOption={setSelectedLetterOption}
            selectedLetterOption={selectedLetterOption}
            isDisabled={isLoading}
            searchValue={searchValue}
            searchedValue={searchedValue}
            setSearchValue={setSearchValue}
            setSearchedValue={setSearchedValue}
            applyFilters={(clearFilters?: boolean) => {
              if (clearFilters) {
                setFilters({
                  filterLetter: '',
                });
                setSearchValue('');
                setSearchedValue('');
              } else {
                setPage(0);
                setFilters({
                  ...filters,
                  filterLetter: selectedLetterOption,
                });
              }
            }}
          />
        </Box>
        <Divider />
        {(isLoading || !customViewSelected) && (
          <Box height="10rem">
            <Loader position="centered" type="inline" />
          </Box>
        )}
        {!isLoading && rows && rows?.length > 0 && (
          <Box
            sx={{
              marginTop: theme => theme.spacing(2),
              marginBottom: theme => theme.spacing(1),
            }}
          >
            <CustomersDataGrid
              loading={isLoading}
              rows={rows}
              availableColumns={availableColumns}
              rowCount={recordCount}
              page={page}
              pageSize={perPage}
              sortModel={sortModel}
              onPageChange={onPageChange}
              onPageSizeChange={onPageSizeChange}
              onSortModelChange={onSortModelChange}
            />
          </Box>
        )}
        {!isLoading && (rows?.length === 0 || !rows) && customViewSelected && (
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            height="10rem"
          >
            <Typography>There are no customers to display.</Typography>
          </Box>
        )}
      </Card>
      {hasBulkRatePermissions && (
        <CustomersApplyRateCodesModal
          customViewId={customViewSelected}
          totalCustomers={recordCount}
          filterLetter={selectedLetterOption}
          searchValue={searchValue}
          open={ratesModalIsOpen}
          onClose={reset => {
            setRatesModalIsOpen(false);
            if (reset) {
              reloadCustomers();
            }
          }}
        />
      )}
    </>
  );
};
